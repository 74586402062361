<template>
  <div class="disclaimerContainer">
    <component :is="this.disclaimerToRender"></component>
    <div class="flex">
      <div class="marginMsg" v-if="this.showAcceptBtn">
        <a class="btn-primary" @click="accept()">{{ labelAccetto }}</a>
      </div>
      <div class="marginMsg" v-else>
        <a class="btn-primary" @click="goBack()">{{ labelIndietro }}</a>
      </div>
      <div class="marginMsg">
        <a class="btn-default" @click="tornaSu()">{{ labelTornaSu }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../Store/store.js";
import { mapMutations, mapGetters } from "vuex";
import disclaimerIT from "../Components/Disclaimer/Disclaimer_IT.vue";
import disclaimerEN from "../Components/Disclaimer/Disclaimer_EN.vue";
import locales from "../mixins/locales.js";

export default {
  data() {
    return {
      disclaimerToRender: null
    };
  },
  props: ["showAcceptBtn"],
  components: { disclaimerIT, disclaimerEN },
  mixins: [locales],
  methods: {
    ...mapMutations(["setCompToRender", "setAcceptDisclaimer"]),
    goBack() {
      this.setCompToRender(this.currentSectionDisclaimer);
    },
    tornaSu() {
      window.scroll(0, 0);
    },
    accept() {
      const hostname = window.location.hostname;
      let domain = store.state.domain;
      if (hostname.indexOf("horta-stg") > -1) {
        domain = "horta-stg.basf.com";
      }

      const max_age = 3600 * 24 * 365 * 1000;
      document.cookie = `ACCEPTED_DISCLAIMER_AGEGO=true; domain=${domain}; path=/; max-age=${max_age}`;
      this.setCompToRender("compLoginGigya");
      this.setAcceptDisclaimer(true);
    }
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      currentSectionDisclaimer: "currentSectionDisclaimer",
      acceptDisclaimer: "acceptDisclaimer",
      globalLocale: "globalLocale"
    })
  },
  mounted() {
    if (this.globalLocale == "IT") {
      this.disclaimerToRender = disclaimerIT;
    } else if (this.globalLocale == "EN") {
      this.disclaimerToRender = disclaimerEN;
    } else {
      this.disclaimerToRender = disclaimerEN;
    }
  }
};
</script>
