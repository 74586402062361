import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let domain = "";
let host = "";
let service = "";
const hostname = window.location.hostname;
if (hostname.indexOf(".local") > -1) {
  host = "https://agrigenius-go.horta-srl.local";
  domain = ".horta-srl.local";
  service = "FITOSPA15TESTAGEGO";
} else if (hostname.indexOf("test.") > -1) {
  host = "https://agrigeniusgotest.horta-srl.it";
  domain = ".horta-srl.it";
  service = "FITOSPA15TESTAGEGO";
} else {
  host = "https://agrigeniusgo.horta-srl.it";
  domain = ".horta-srl.it";
  service = "FITOSPA15AGEGO";
}

export const store = new Vuex.Store({
  state: {
    compToRender: "compLoginGigya",
    urlJWTPass: `${host}/pass`,
    urlAgriGo: `${host}/api`,
    urlCiamAuth: `${host}/horta_ciam_auth`,
    urlPhp: `${host}/fito/dss`,
    domain: `${domain}`,
    servIdIn: `${service}`,
    token: "",
    itemInfo: {},
    upId: "",
    example: {},
    filterOn: false,
    totalList: [], //lista totale oggetti
    itemList: [], //numero di oggetti da mostrare nelle tabelle master
    prodottiPreferitiList: [],
    currentPage: 1,
    maxPage: 1,
    limit: 15,
    totalItem: 0, //count(*) oggetti tabella
    currentSection: "",
    geoLat: 0,
    geoLng: 0,
    maxUp: 999, //not used anymore
    showNoAziende: false,
    showNoUp: false,
    username: "",
    password: "",
    insertMsg: "",
    showMsg: false,
    isActive: true,
    isDisabledAvanti: false,
    isDisabledIndietro: false,
    isDisabledPrima: false,
    isDisabledUltima: false,
    role: "",
    reteID: "",
    globalLocale: "IT",
    showHeader: true,
    currentSectionDisclaimer: "",
    acceptDisclaimer: false,
    varCodService: 121
  },
  getters: {
    varCodService: state => {
      return state.varCodService;
    },
    acceptDisclaimer: state => {
      return state.acceptDisclaimer;
    },
    currentSectionDisclaimer: state => {
      return state.currentSectionDisclaimer;
    },
    showHeader: state => {
      return state.showHeader;
    },
    globalLocale: state => {
      return state.globalLocale;
    },
    role: state => {
      return state.role;
    },
    reteID: state => {
      return state.reteID;
    },
    isDisabledAvanti: state => {
      return state.isDisabledAvanti;
    },
    isDisabledIndietro: state => {
      return state.isDisabledIndietro;
    },
    isDisabledPrima: state => {
      return state.isDisabledPrima;
    },
    isDisabledUltima: state => {
      return state.isDisabledUltima;
    },
    token: state => {
      return state.token;
    },
    isActive: state => {
      return state.isActive;
    },
    insertMsg: state => {
      return state.insertMsg;
    },
    showMsg: state => {
      return state.showMsg;
    },
    compToRender: state => {
      return state.compToRender;
    },
    username: state => {
      return state.username;
    },
    password: state => {
      return state.password;
    },
    geoLat: state => {
      return state.geoLat;
    },
    geoLng: state => {
      return state.geoLng;
    },
    itemInfo: state => {
      return state.itemInfo;
    },
    upId: state => {
      return state.upId;
    },
    example: state => {
      return state.example;
    },
    filterOn: state => {
      return state.filterOn;
    },
    itemList: state => {
      return state.itemList;
    },
    prodottiPreferitiList: state => {
      return state.prodottiPreferitiList;
    },
    currentPage: state => {
      return state.currentPage;
    },
    maxPage: state => {
      return state.maxPage;
    },
    limit: state => {
      return state.limit;
    },
    totalItem: state => {
      return state.totalItem;
    },
    currentSection: state => {
      return state.currentSection;
    },
    prodottiUrl: state => {
      return state.prodottiUrl;
    },
    prodottiPreferitiUrl: state => {
      return state.prodottiPreferitiUrl;
    },
    fasiUrl: state => {
      return state.fasiUrl;
    },
    maxUp: state => {
      return state.maxUp;
    },
    showNoAziende: state => {
      return state.showNoAziende;
    },
    showNoUp: state => {
      return state.showNoUp;
    },
    totalList: state => {
      return state.totalList;
    }
  },
  mutations: {
    setAcceptDisclaimer(state, payload) {
      state.acceptDisclaimer = payload;
    },
    setCurrentSectionDisclaimer(state, payload) {
      state.currentSectionDisclaimer = payload;
    },
    setGlobalLocale(state, payload) {
      state.globalLocale = payload.toUpperCase();
    },
    setReteID(state, payload) {
      state.reteID = payload;
    },
    setIsDisabledAvanti(state, payload) {
      state.isDisabledAvanti = payload;
    },
    setIsDisabledIndietro(state, payload) {
      state.isDisabledIndietro = payload;
    },
    setIsDisabledPrima(state, payload) {
      state.isDisabledPrima = payload;
    },
    setIsDisabledUltima(state, payload) {
      state.isDisabledUltima = payload;
    },
    setIsActive(state, payload) {
      state.isActive = payload;
    },
    setToken(state, payload) {
      state.token = payload;
    },
    setShowMsg(state, payload) {
      state.showMsg = payload;
    },
    setInsertMsg(state, payload) {
      state.insertMsg = payload;
    },
    setPassword(state, payload) {
      state.password = payload;
    },
    setUsername(state, payload) {
      state.username = payload;
    },
    setCompToRender(state, payload) {
      state.compToRender = payload;
    },
    setItemInfo(state, payload) {
      state.itemInfo = payload;
    },
    setUpId(state, payload) {
      state.upId = payload;
    },
    setExample(state, payload) {
      state.example = payload;
    },
    toggleFilterOn(state, payload) {
      state.filterOn = payload;
    },
    setItemList(state, payload) {
      state.itemList = payload;
    },
    setCurrentPage(state, payload) {
      state.currentPage = payload;
    },
    setMaxPage(state, payload) {
      state.maxPage = payload;
    },
    setTotalItem(state, payload) {
      state.totalItem = payload;
    },
    setCurrentSection(state, payload) {
      state.currentSection = payload;
    },
    setProdottiPreferitiList(state, payload) {
      state.prodottiPreferitiList = payload;
    },
    setGeoLat(state, payload) {
      state.geoLat = payload;
    },
    setGeoLng(state, payload) {
      state.geoLng = payload;
    },
    setShowNoAziende(state, payload) {
      state.showNoAziende = payload;
    },
    setShowNoUp(state, payload) {
      state.showNoUp = payload;
    },
    setTotalList(state, payload) {
      state.totalList = payload;
    },
    setRole(state, payload) {
      state.role = payload;
    },
    setShowHeader(state, payload) {
      state.showHeader = payload;
    },
    setVarCodService(state, payload) {
      state.varCodService = payload;
    }
  }
});
