<template>
  <div>
    <div class="shadowBox titleBox">
      {{ labelInsetti }} - {{ itemInfo.UPDes }}
    </div>
    <div class="shadowBox calcHeight">
      <button
        :class="activePanel == 0 ? 'accordion-active accordion' : 'accordion'"
        @click="changePanel(0)"
      >
        {{ labelTignoletta }}
      </button>
      <div class="panel" v-if="activePanel == 0">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->
        <button
          :class="
            activeSubPanel == 0 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(0)"
          v-if="isReady"
        >
          1<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" mode="out-in" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 0">
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.adulti_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelLarve }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.larve_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelCrisalidi }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.crisalidi_svernanti_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G1.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">{{ labelLegend }}</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <button
          :class="
            activeSubPanel == 1 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(1)"
          v-if="isReady"
        >
          2<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" mode="out-in" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 1">
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.adulti_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelLarve }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.larve_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelCrisalidi }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.pupe_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G2.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">{{ labelLegend }}</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <button
          :class="
            activeSubPanel == 2 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(2)"
          v-if="isReady"
        >
          3<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" mode="out-in" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 2">
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.adulti_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelLarve }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.larve_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelCrisalidi }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.pupe_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G3.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">{{ labelLegend }}</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <button
          :class="
            activeSubPanel == 3 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(3)"
          v-if="isReady"
        >
          4<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" mode="out-in" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 3">
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G4.adulti_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G4.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelLarve }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G4.larve_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelCrisalidi }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G4.pupe_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G4.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">Legenda</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>

      <button
        :class="activePanel == 1 ? 'accordion-active accordion' : 'accordion'"
        @click="changePanel(1)"
      >
        {{ labelScafoideo }}
      </button>
      <div class="panel" v-if="activePanel == 1">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->
        <div id="panelScafo" v-if="isReady">
          <div class="bold grigioscuro">{{ labelNeanidi }} I</div>
          <div class="containerFlex">
            <div
              class="dayBox heatmapBox"
              :style="`background-color: ${day.fill}`"
              :class="index == 10 ? 'todayBox' : ''"
              v-for="(day, index) in dati.neanidi_I_heatmap"
              :key="index"
            ></div>
          </div>
          <div class="bold grigioscuro">{{ labelNeanidi }} II</div>
          <div class="containerFlex">
            <div
              class="dayBox heatmapBox"
              :style="`background-color: ${day.fill}`"
              :class="index == 10 ? 'todayBox' : ''"
              v-for="(day, index) in dati.neanidi_II_heatmap"
              :key="index"
            ></div>
          </div>
          <div class="bold grigioscuro">{{ labelNeanidi }} III</div>
          <div class="containerFlex">
            <div
              class="dayBox heatmapBox"
              :style="`background-color: ${day.fill}`"
              :class="index == 10 ? 'todayBox' : ''"
              v-for="(day, index) in dati.ninfe_III_heatmap"
              :key="index"
            ></div>
          </div>
          <div class="bold grigioscuro">{{ labelNeanidi }} IV</div>
          <div class="containerFlex">
            <div
              class="dayBox heatmapBox"
              :style="`background-color: ${day.fill}`"
              :class="index == 10 ? 'todayBox' : ''"
              v-for="(day, index) in dati.ninfe_IV_heatmap"
              :key="index"
            ></div>
          </div>
          <div class="bold grigioscuro">{{ labelNeanidi }} V</div>
          <div class="containerFlex">
            <div
              class="dayBox heatmapBox"
              :style="`background-color: ${day.fill}`"
              :class="index == 10 ? 'todayBox' : ''"
              v-for="(day, index) in dati.ninfe_V_heatmap"
              :key="index"
            ></div>
          </div>
          <div class="bold grigioscuro">{{ labelAdulti }}</div>
          <div id="scafoAdulti" class="containerFlex">
            <div
              class="dayBox heatmapBox"
              :style="`background-color: ${day.fill}`"
              :class="index == 10 ? 'todayBox' : ''"
              v-for="(day, index) in dati.adulti_heatmap"
              :key="index"
            ></div>
          </div>

          <div id="arrayDateLegenda" class="containerFlex grigioscuro">
            <div
              class="dayBox dateBoxInsetti"
              :style="index == 10 ? 'font-weight: bold' : ''"
              v-for="(day, index) in dati.adulti_heatmap"
              :key="index"
            >
              {{ convertData(day.x) }}
            </div>
          </div>

          <hr />
          <div class="bold" @click="toggleLegenda">
            <div v-show="displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
            </div>
            <div v-show="!displayLegenda" class="grigioscuro">
              {{ labelLegend }}
              <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
            </div>
          </div>
          <br />
          <div v-if="displayLegenda">
            <div class="bold grigioscuro">{{ labelLegend }}</div>
            <div class="containerFlex borderLegend">
              <div
                v-for="(item, index) in this.legenda"
                :key="index"
                class="legendBox grigioscuro"
              >
                <div
                  class="legendItem"
                  :style="`background-color:${item[0]}`"
                ></div>
                <div>
                  {{ item[1] }}
                </div>
              </div>
              <div class="legendBox hideDiv">
                <div class="legendItem"></div>
                <div>nn</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        :class="activePanel == 2 ? 'accordion-active accordion' : 'accordion'"
        @click="changePanel(2)"
      >
        {{ labelPlano }}
      </button>
      <div class="panel" v-if="activePanel == 2">
        <app-dialog v-if="!isReady" />
        <!-- <div id="waitingPanel" class="waiting" v-if="!isReady">
          <div class="headerWaiting">
            {{ labeWaiting }}
          </div>
          <div class="bodyWaiting">
            {{ labelElab }}
          </div>
          <div class="loaderIcon">
            <img src="/../images/agrishare/horta/ajax-loader.gif" />
          </div>
        </div> -->
        <button
          :class="
            activeSubPanel == 0 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(0)"
          v-if="isReady"
        >
          1<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 0">
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelGiovani }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.giovani_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G1.adulti_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G1.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">{{ labelLegend }}</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <button
          :class="
            activeSubPanel == 1 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(1)"
          v-if="isReady"
        >
          2<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 1">
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelGiovani }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.giovani_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G2.adulti_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G2.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">{{ labelLegend }}</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <button
          :class="
            activeSubPanel == 2 ? 'accordion-active accordion' : 'accordion'
          "
          @click="changeSubPanel(2)"
          v-if="isReady"
        >
          3<sup>a</sup> {{ labelGen }}
        </button>
        <transition name="slide-fade" v-if="isReady">
          <div class="panel" v-if="activeSubPanel == 2">
            <div class="bold grigioscuro">{{ labelUova }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.uova_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelGiovani }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.giovani_heatmap"
                :key="index"
              ></div>
            </div>
            <div class="bold grigioscuro">{{ labelAdulti }}</div>
            <div class="containerFlex">
              <div
                class="dayBox heatmapBox"
                :style="`background-color: ${day.fill}`"
                :class="index == 10 ? 'todayBox' : ''"
                v-for="(day, index) in dati.G3.adulti_heatmap"
                :key="index"
              ></div>
            </div>

            <div class="containerFlex grigioscuro">
              <div
                class="dayBox dateBoxInsetti"
                :style="index == 10 ? 'font-weight: bold' : ''"
                v-for="(day, index) in dati.G3.adulti_heatmap"
                :key="index"
              >
                {{ convertData(day.x) }}
              </div>
            </div>

            <hr />
            <div class="bold" @click="toggleLegenda">
              <div v-show="displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-up']" />
              </div>
              <div v-show="!displayLegenda" class="grigioscuro">
                {{ labelLegend }}
                <font-awesome-icon :icon="['fas', 'chevron-circle-down']" />
              </div>
            </div>
            <br />
            <div v-if="displayLegenda">
              <div class="bold grigioscuro">{{ labelLegend }}</div>
              <div class="containerFlex grigioscuro borderLegend">
                <div
                  v-for="(item, index) in this.legenda"
                  :key="index"
                  class="legendBox"
                >
                  <div
                    class="legendItem"
                    :style="`background-color:${item[0]}`"
                  ></div>
                  <div>
                    {{ item[1] }}
                  </div>
                </div>
                <div class="legendBox hideDiv">
                  <div class="legendItem"></div>
                  <div>nn</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import queries from "../mixins/queries.js";
import locales from "../mixins/locales.js";

export default {
  mixins: [queries, locales],
  data() {
    return {
      title: "Insetti",
      option: "",
      isReady: false,
      activePanel: -1,
      activeSubPanel: -1,
      dati: {},
      legenda: [],
      displayLegenda: false
    };
  },
  computed: {
    ...mapGetters({
      compToRender: "compToRender",
      itemInfo: "itemInfo",
      token: "token",
      globalLocale: "globalLocale"
    })
  },
  methods: {
    changePanel(index) {
      this.displayLegenda = false;
      if (this.activePanel != index) {
        this.isReady = false;
        this.activePanel = index;
        const insettiSubType = index =>
          ({
            0: "tignoletta",
            1: "scafoideo",
            2: "planococco"
          }[index]);

        this.fetchData(insettiSubType(index), index);
      }
    },
    fetchData(subType, index) {
      this.queryChart(
        this.token,
        "insetti",
        subType,
        this.itemInfo.UPID,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      )
        .then(result => {
          console.log(result);
          this.dati = result.data?.data?.grafici?.result;
          const avg_days = 18;
          let num_giorni = 0;
          let adding_days = 0;
          let fake_date = "";

          if (this.dati) {
            for (const [, value] of Object.entries(this.dati)) {
              num_giorni = value.adulti_heatmap?.length;
              adding_days = avg_days - num_giorni;
              if (adding_days > 0) {
                fake_date = new Date(value.adulti_heatmap[10].x);
                for (let x = 0; x < adding_days; ++x) {
                  this.addDay(fake_date, 1);
                  value.adulti_heatmap.push({
                    x: this.fakeDay(fake_date),
                    y: "P",
                    heat: null,
                    fill: "white",
                    hoverFill: "white"
                  });
                }
              }
            }

            for (const [, value] of Object.entries(this.dati)) {
              num_giorni = value.uova_heatmap?.length;
              adding_days = avg_days - num_giorni;
              if (adding_days > 0) {
                fake_date = new Date(value.uova_heatmap[10].x);
                for (let x = 0; x < adding_days; ++x) {
                  this.addDay(fake_date, 1);
                  value.uova_heatmap.push({
                    x: this.fakeDay(fake_date),
                    y: "P",
                    heat: null,
                    fill: "white",
                    hoverFill: "white"
                  });
                }
              }
            }

            for (const [, value] of Object.entries(this.dati)) {
              num_giorni = value.giovani_heatmap?.length;
              adding_days = avg_days - num_giorni;
              if (adding_days > 0) {
                fake_date = new Date(value.giovani_heatmap[10].x);
                for (let x = 0; x < adding_days; ++x) {
                  this.addDay(fake_date, 1);
                  value.giovani_heatmap.push({
                    x: this.fakeDay(fake_date),
                    y: "P",
                    heat: null,
                    fill: "white",
                    hoverFill: "white"
                  });
                }
              }
            }

            for (const [, value] of Object.entries(this.dati)) {
              num_giorni = value.length;
              adding_days = avg_days - num_giorni;
              if (adding_days > 0) {
                fake_date = new Date(value[10].x);
                for (let x = 0; x < adding_days; ++x) {
                  this.addDay(fake_date, 1);
                  value.push({
                    x: this.fakeDay(fake_date),
                    y: "P",
                    heat: null,
                    fill: "white",
                    hoverFill: "white"
                  });
                }
              }
            }
          }

          this.activePanel = index;
          this.activeSubPanel = 0;

          this.createLegenda();
          this.loaded();
        })
        .catch(error => {
          console.log(error);
        });
    },
    createLegenda() {
      let myMap = new Map();

      myMap.set("white", "0%");
      myMap.set("#ffd9cc", "1-25%");
      myMap.set("#ff8c66", "25-50%");
      myMap.set("#e63900", "50-75%");
      myMap.set("#802000", "75-100%");

      this.legenda = myMap;
    },
    loaded() {
      this.isReady = true;
    },
    addDay(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
    fakeDay(date) {
      let fake_day = "";
      let fake_date = "";
      if (date.getDate() < 10) {
        fake_day = "0" + date.getDate();
      } else {
        fake_day = date.getDate();
      }
      fake_date = `${date.getFullYear()}-${date.getMonth() + 1}-${fake_day}`;
      return fake_date;
    },
    changeSubPanel(index) {
      this.displayLegenda = false;
      this.activeSubPanel = index;
    },
    convertData(date) {
      if (!date.length) return "-";
      if (date) return moment(date).format("DD/MM");
      return date;
    },
    toggleLegenda() {
      this.displayLegenda = !this.displayLegenda;
    }
  },
  created() {}
};
</script>

<style scoped>
.hideDiv {
  opacity: 0;
}

.dateBoxInsetti {
  transform: rotate(-90deg);
  transform-origin: 18px 17px;
  height: 35px;
  width: 15px;
  border: none;
}

@media screen and (min-width: 768px) {
  .dateBoxInsetti {
    transform-origin: 25px 13px;
  }
}

@media screen and (min-width: 1024px) {
  .dateBoxInsetti {
    transform-origin: 27px 9px;
    height: 40px;
  }
}
</style>
