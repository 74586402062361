<template>
  <div>
    <div class="shadowBox titleBox">
      {{ title }}
    </div>
    <div>
      <div class="grigioscuro bold textPanel shadowBox">
        <p>{{ labelPwaInfo }}</p>
        <p>1) {{ labelPasso1 }}</p>
        <p style="text-align: center">
          <img src="@/assets/share.png" style="width: 25px;" />
        </p>
        <p>2) {{ labelPasso2 }}</p>
        <p>3) {{ labelPasso3 }}</p>
        <p>4) {{ labelPasso4 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import locales from "../mixins/locales.js";

export default {
  data() {
    return {
      title: "Attenzione"
    };
  },
  mixins: [locales],
  computed: {
    ...mapGetters({
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations([
      "setCompToRender",
      "setShowHeader",
      "setAcceptDisclaimer",
      "setGlobalLocale"
    ]),
    go2comp(location) {
      this.setCompToRender(location);
    }
  },
  created() {
    let go2login = false;
    let acceptedDisclaimer = true;
    if (this.$route.query.lang != null)
      this.setGlobalLocale(this.$route.query.lang);

    if (document.cookie.indexOf("ACCEPTED_DISCLAIMER_AGEGO=true") > -1) {
      acceptedDisclaimer = true;
      this.setAcceptDisclaimer(true);
    }

    this.setShowHeader(true);
    if (navigator.userAgent.indexOf("Android") > -1) {
      go2login = true;
      acceptedDisclaimer = true;
      this.setShowHeader(false);
    }
    if (window.matchMedia("(display-mode: standalone)").matches) {
      go2login = true;
    }

    if (go2login) {
      if (!acceptedDisclaimer) {
        console.log("disclaimer NON trovato");
        this.go2comp("compDisclaimer");
      } else {
        console.log("disclaimer trovato");
        this.go2comp("compLoginGigya");
      }
    }
  }
};
</script>

<style scoped>
.brand {
  border: 1px solid;
  background-color: #65ac1e;
  color: #fff;
  margin-right: 10px;
  margin-left: 10px;
}

.brand-ios {
  padding-top: 25px;
  padding-bottom: 5px;
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.brand-android {
  padding-top: 25px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.textPanel {
  height: calc(100vh - 170px);
}
</style>
