<template>
  <div>
    <div class="alert-danger alert">
      <p>{{ errMsg1 }}</p>
      <p>{{ errMsg9 }}</p>
      <p>
        {{ errMsg3 }}
        <button class="bnt-move" @click="go2comp('compLoginGigya')">
          qui
        </button>
        {{ errMsg5 }}
      </p>
      <p>{{ errMsg6 }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import locales from "../mixins/locales.js";

export default {
  data() {
    return {};
  },
  mixins: [locales],
  computed: {
    ...mapGetters({
      globalLocale: "globalLocale"
    })
  },
  methods: {
    ...mapMutations(["setCompToRender"]),
    go2comp(location) {
      this.setCompToRender(location);
    }
  }
};
</script>

<style scoped>
.bnt-move {
  border-radius: 5px;
  padding: 5px;
  background-color: darkgray;
  color: white;
  font-weight: bold;
  height: 20px;
  line-height: 5px;
}
</style>
